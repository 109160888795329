import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
        >
          <path
            d="M6143 8703 c-10 -4 -13 -85 -13 -359 l0 -354 945 0 945 0 0 360 0
360 -932 -1 c-513 0 -939 -3 -945 -6z"
          />
          <path
            d="M5440 8684 c-228 -60 -405 -205 -501 -410 -80 -168 -74 -35 -74
-1744 0 -1646 -3 -1566 53 -1695 74 -171 203 -291 379 -352 158 -54 354 -30
501 63 36 22 225 203 502 478 383 381 453 446 500 467 l55 24 580 3 580 2 3
358 2 357 -647 -5 -648 -5 -88 -28 c-105 -32 -208 -81 -297 -138 -42 -28 -184
-161 -400 -376 -185 -183 -339 -333 -343 -333 -4 0 -7 754 -7 1675 l0 1675
-47 -1 c-27 0 -73 -7 -103 -15z"
          />
          <path
            d="M5380 3445 l0 -485 95 0 95 0 0 35 c0 19 5 35 10 35 6 0 10 -4 10 -8
0 -5 24 -22 53 -38 47 -26 61 -29 147 -29 82 0 102 4 150 26 98 47 158 122
186 232 47 180 -45 373 -207 432 -83 31 -198 27 -266 -8 -28 -15 -55 -32 -60
-39 -4 -7 -10 63 -13 157 l-5 170 -97 3 -98 3 0 -486z m422 55 c86 -24 131
-91 131 -191 0 -55 -5 -76 -26 -112 -69 -117 -247 -113 -309 7 -16 29 -22 59
-22 106 0 76 18 116 69 157 53 42 93 50 157 33z"
          />
          <path d="M7170 3445 l0 -485 100 0 100 0 -2 483 -3 482 -97 3 -98 3 0 -486z" />
          <path
            d="M3892 3880 c-174 -24 -282 -129 -282 -275 0 -78 15 -118 60 -163 47
-45 95 -66 250 -107 148 -39 187 -58 210 -98 14 -25 13 -30 -5 -64 -25 -45
-65 -61 -160 -62 -88 -1 -184 21 -253 59 l-54 29 -34 -74 c-19 -40 -34 -76
-34 -79 0 -12 103 -57 177 -77 100 -28 271 -31 354 -6 129 39 210 129 217 243
7 112 -34 182 -139 232 -24 11 -103 36 -176 55 -73 19 -147 43 -165 54 -69 40
-60 116 19 154 63 30 191 24 283 -15 36 -15 69 -28 74 -29 5 -1 20 25 32 58
40 100 41 93 -18 119 -102 44 -238 62 -356 46z"
          />
          <path
            d="M6347 3863 c-4 -3 -7 -208 -7 -455 l0 -448 105 0 105 0 2 163 3 162
208 3 207 2 0 85 0 85 -210 0 -211 0 3 123 3 122 238 3 237 2 0 80 0 80 -338
0 c-186 0 -342 -3 -345 -7z"
          />
          <path
            d="M7780 3656 c-112 -30 -193 -95 -241 -194 -34 -69 -39 -206 -10 -283
31 -82 88 -141 179 -186 75 -37 78 -38 191 -38 75 0 128 5 151 14 55 21 130
74 130 91 0 9 -22 37 -48 63 l-48 48 -25 -19 c-62 -48 -187 -57 -260 -19 -43
23 -89 76 -89 103 0 11 52 14 263 16 l262 3 3 58 c12 231 -223 407 -458 343z
m198 -163 c34 -25 72 -80 72 -104 0 -18 -11 -19 -174 -19 l-175 0 10 32 c11
38 53 85 94 104 43 20 138 13 173 -13z"
          />
          <path
            d="M4477 3653 c-11 -10 -8 -392 3 -462 14 -81 39 -130 91 -174 52 -45
116 -67 193 -67 81 0 127 14 181 55 l45 35 0 -40 0 -40 95 0 95 0 0 350 0 351
-97 -3 -98 -3 -5 -217 c-5 -199 -7 -219 -26 -244 -40 -54 -70 -69 -139 -69
-57 0 -68 4 -94 29 -43 41 -51 91 -51 310 l0 196 -93 0 c-52 0 -97 -3 -100 -7z"
          />
          <path
            d="M8310 3656 c0 -2 56 -79 125 -171 69 -92 125 -171 125 -174 0 -3 -19
-31 -42 -61 -38 -50 -188 -248 -211 -277 -7 -10 14 -13 99 -13 l109 1 75 104
c41 57 78 104 82 104 3 1 25 -25 47 -56 22 -32 55 -79 73 -105 l33 -47 113 -1
114 0 -29 38 c-15 21 -75 99 -132 175 -56 75 -101 143 -98 150 3 8 54 78 114
156 59 78 114 151 122 161 13 19 11 20 -91 20 l-104 0 -74 -100 c-41 -55 -78
-100 -81 -100 -4 0 -39 45 -79 100 l-73 100 -109 0 c-59 0 -108 -2 -108 -4z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
